import { Popover } from "@headlessui/react";
import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import { stripTrailingSlash } from "utils";

export default function Sidebar({ open, onClose, settings }) {
  const { asPath } = useRouter();
  return (
    <div
      className={` left-0 top-24 transform transition-all ease-in-out duration-200 bg-white w-full p-2 shadow-2xl  ${
        open ? "opacity-100 z-50 absolute" : "opacity-0 z-0 hidden"
      }`}
    >
      <div className="flex items-center justify-end">
        <button onClick={onClose} className="h-8 w-8" title="Close Sidebar">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="grid grid-cols-1 my-8">
        {settings?.navigation?.map((nav, id) => (
          <Link href={nav.url} key={`sidebar_${nav.url}_${id}`}>
            <a
              className={`w-full font-black  mb-2 mr-2 px-3 py-2 ${
                asPath === stripTrailingSlash(nav.url)
                  ? "bg-gradient-to-tr  from-blue-900 to-blue-400  text-white"
                  : "text-blue-900"
              }`}
            >
              {nav.label}
            </a>
          </Link>
        ))}
      </div>
    </div>
  );
}
