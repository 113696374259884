import Link from "next/link";

export default function Footer({ settings }) {
  return (
    <div
      className="h-96 w-full bg-gradient-to-tr  from-primary-900 to-primary-400"
      data-cy="Footer"
    >
      <div className="container mx-auto">
        <div className="md:flex  py-8 px-4 md:py-24">
          <div className="md:w-1/2">
            <div className="flex">
              <ul className="mr-4">
                {settings?.navigation?.map((nav, id) => (
                  <li
                    key={`secondary_nav_${nav.label}_${id}`}
                    className="text-white font-medium uppercase"
                  >
                    <Link href={nav.url}>
                      <a>{nav.label}</a>
                    </Link>
                  </li>
                ))}
              </ul>
              <ul>
                {settings?.secondary_navigation?.map((nav, id) => (
                  <li
                    key={`secondary_nav_${nav.label}_${id}`}
                    className="text-white font-medium uppercase"
                  >
                    {nav.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="md:w-1/2">
            <div className="text-4xl text-white font-thin mb-4">{settings.title}</div>
            <p className="text-white font-medium">
              From the “tap” to the technique, The Speckled Truth will cover everything you need to
              know about the experience surrounding speckled trout fishing. For more information
              check out the about me section or contact me if you have any questions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
