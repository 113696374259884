import Logo from "public/logo_a.png";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/dist/client/router";
import { useState } from "react";
import Sidebar from "./Sidebar";
import { stripTrailingSlash } from "utils";

interface NavItem {
  label: string;
  url: string;
}

interface NavProps {
  settings: {
    navigation: NavItem[];
  };
}

export default function Nav({ settings }: NavProps) {
  const [sidebar, setSidebar] = useState(false);
  const { asPath } = useRouter();
  return (
    <>
      <nav className="h-24 shadow-xl flex items-center bg-white w-full">
        <div className="container mx-auto">
          <div className="flex items-center px-4 md:mx-0 w-full">
            <div className="max-w-[175px] md:max-w-[300px]">
              <Link href="/">
                <a>
                  <Image src={Logo} alt="Speckled Truth Logo" data-cy="Logo" />
                </a>
              </Link>
            </div>
            <div className="mr-auto"></div>

            <div className="block md:hidden mx-4 text-blue-900">
              <button onClick={() => setSidebar(!sidebar)} title="Toggle Navigation Menu">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
              <Sidebar open={sidebar} onClose={() => setSidebar(false)} settings={settings} />
            </div>
            <div className="md:mr-8"></div>
            <div className="flex items-center justify-between">
              <ul className="hidden md:flex items-center mr-auto">
                {settings?.navigation?.map((item) => (
                  <li className="mr-2" key={`nav_item_${item.label}`} data-cy="GhostNavLink">
                    <Link href={item.url}>
                      <a
                        className={`font-medium uppercase px-4 py-3 ${
                          asPath === stripTrailingSlash(item.url)
                            ? "text-white bg-gradient-to-tr  from-primary-900 to-primary-400 rounded-sm transform"
                            : "text-gray-700"
                        }`}
                      >
                        {item.label}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="md:block hidden">
                <Link href="/search">
                  <a title="Search">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-blue-900"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
