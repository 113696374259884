export function throttle<T extends (...args: any[]) => void>(
  func: T,
  threshold: number = 250,
  scope?: any
): T {
  let last: number, deferTimer: any;
  return function (this: any) {
    let context = scope || this;

    let now = Date.now(),
      args = arguments;
    if (last && now < last + threshold) {
      // hold on to it
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function () {
        last = now;
        func.apply(context, args);
      }, threshold);
    } else {
      last = now;
      func.apply(context, args);
    }
  } as T;
}
/**
 * Strip trailing slash only if url > 1 if / is sent it will return /
 * @param str string to have trailing slash stripped will only work on a string > than length 1
 * @returns
 */
export function stripTrailingSlash(str: string) {
  if (str.length > 1) {
    return str.endsWith("/") ? str.slice(0, -1) : str;
  } else {
    return str;
  }
}


interface ImgProxyProps {
  width?: number;
  signature?: string;
  resizingType?: string;
  height?: number;
  enlarge?: number;
  gravity?: string;
  url: string;
  extension?: string;
}

export function buildImgProxyUrl({
  signature = "insecure",
  resizingType = "auto",
  width = 0,
  height = 0,
  gravity = "sm",
  enlarge = 0,
  url,
  extension = "@webp",
}: ImgProxyProps): string {
  return `${process.env.NEXT_PUBLIC_IMG_PROXY_URL}/${signature}/${resizingType}/${width}/${height}/${gravity}/${enlarge}/plain/${url}${extension}`;
}
